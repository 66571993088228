import { required } from 'vee-validate/dist/rules';
<template>
    <v-container fluid>
      <div class="from-content">
        <div class="from">
          <v-form ref="form" @submit.prevent="validateForm">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  @keydown.enter="focusNext"
                  :label="$t('workingYear.min')"
                  :rules="minRules"
                  required
                  :hint="`${server_errors.from_year}`"
                  dense
                  outlined
                  v-model="item.from_year"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" style="margin-top: -30px;">
                <v-checkbox 
                  v-model="item.setComparisonMarkUp" 
                  :label="$t('workingYear.comparison_mark_up')" 
                  hide-details
                  class="lfont"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="12" style="margin-top: 10px;">
                <v-text-field
                  :disabled="item.setComparisonMarkUp"
                  @keydown.enter="focusNext"
                  :label="$t('workingYear.max')"
                  dense
                  outlined
                  v-model="item.to_year"
                  :rules="toYearRules"
                  required
                  :hint="`${server_errors.to_year}`"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <vuetify-money 
                  dense 
                  :outlined="true" 
                  :options="formatNumber"
                  v-model="item.budget" 
                  :hint="`${server_errors.budget}`" 
                  persistent-hint 
                  type="number"
                  :rules="budgetRules" 
                  required 
                />
              </v-col>
              <v-col cols="8">
                <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                  {{ $t("workingYear.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        btnLoading: false,
        formatNumber: {
          length: 11,
          precision: 0
        },
        item: {
          from_year: "",
          to_year: "",
          budget: 0,
          setComparisonMarkUp: false,
        },
        minRules: [
          v => !!v || 'field is required...'
        ],
        maxRules: [
          v => !!v || 'field is required...'
        ],
        budgetRules: [
          v => !!v || 'field is required...'
        ],
        server_errors: {
          from_year: "",
          to_year: "",
          budget: ""
        }
      }
    },
    computed: {
      toYearRules() {
        return this.item.setComparisonMarkUp ? [] : this.maxRules;
      },
    },
    methods: {
      focusNext(e) {
        const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
        const index = inputs.indexOf(e.target);
        if (index < inputs.length) {
          inputs[index + 1].focus();
        }
      },
      validateForm() {
        if (this.$refs.form.validate()) {
          this.saveChange();
          this.btnLoading = true
        }
      },
      saveChange() {
        this.$axios.post(`company/annual-bonus`, {
          from_year: this.item.from_year,
          to_year: this.item.to_year,
          budget: this.item.budget,
        }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
            }, 300);
            this.$router.push({
              name: "working.year.index",
            })
            this.btnLoading = false;
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
            this.btnLoading = false;
          }
          this.btnLoading = false;
        })
  
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .from-content {
    width: 100%;
    height: 100vh;
    padding: 40px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .from {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
    }
  
  }
  </style>
  