<template>
  <div class="branch-form">
    <v-form class="form" ref="form" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="6">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              class="label-input"
              :label="$t('branch.branchName')"
              :rules="nameRules"
              v-model="items.name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="4" md="6">
          <v-select
              @keydown.enter="focusNext"
              :label="$t('branch.province')"
              dense
              outlined
              v-model="province_id"
              :items="provinces"
              item-text="name"
              :rules="nameRules"
              required
              item-value="id">
          </v-select>
        </v-col>
        <v-col cols="4" md="6">
          <v-select
              @keydown.enter="focusNext"
              :label="$t('branch.district')"
              dense
              outlined
              v-model="items.district_id"
              :rules="nameRules"
              required
              :items="districts"
              item-text="name"
              @change="selectDistrictId($event)"
              item-value="id">
          </v-select>
        </v-col>
        <v-col cols="6" style="position: relative">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              v-model="items.village"
              dense
              class="label-input"
              :label="$t('branch.village')"
          >
          </v-text-field>

        </v-col>
        <v-col cols="12">
          <v-textarea
              @keydown.enter="focusNext"
              outlined
              dense
              v-model="items.description"
              :label="$t('branch.description')"
          >

          </v-textarea>
        </v-col>

        <v-col cols="8">
          <v-btn class="btn-save-change" type="submit">
            {{ $t("branch.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>


export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      items: {
        name: "",
        district_id: "",
        village: "",
        description: ""
      },
      server_errors: {
        name: "",
        province_id: "",
        district_id: "",
        village: "",
        description: ""
      },
      provinces: [],
      districts: [],
      village: [],
      districtId: "",
      selectedProvince: {},
      selectedDistrict: {},
      filterDistricts: [],
      province_id: "",
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    FetchDistrict() {
      this.$axios.get(`list/addresses`).then((res) => {
        const items = res.data;
        this.provinces = items.provinces;
        this.selectedProvince = {...this.provinces[0]};
        this.filterDistricts = items.districts;
      }).catch((error) => {
        console.log(error)
      })
    },
    FilterDistricts(provinceID) {
      this.districts = this.filterDistricts.filter(item => {
        if (item.province_id == provinceID) {
          return item;
        }
      });
    },
    saveChange() {
      this.$axios.post(`company/add/company/branch`, this.items).then((res) => {
        if (res) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
        this.$router.push({
          name: "branch.index"
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

  watch: {
    'province_id': function (provinceID) {
      this.FilterDistricts(provinceID);
    },
  },
  created() {
    this.FetchDistrict();
  }
}
</script>

<style scoped lang="scss">
.branch-form {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 800px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
